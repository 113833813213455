<template>
  <v-container class="">
    <div class="mt-10">
      <h3 class="text-center">{{ $t("message.label-information-regis") }}</h3>
      <v-divider style="background-color: grey"></v-divider>
      <p class="text-center pt-2">
        {{ $t("message.label-desc-information") }}
      </p>


      <!-- FORM REGISTER -->
      <div class="px-2">
        <form @submit.prevent="checkRegister">
          <div class="mt-4 mb-2">
            <h3>{{ $t("message.label-login-info") }}</h3>
            <v-divider style="background-color: grey"></v-divider>
          </div>
          <table class="table table-bordered border-primary" style="padding: 0px">
            <tbody>
              <tr>
                <th class="">
                  <span style="font-size: 14px">{{ $t("message.label-email") }}</span>
                  </th>
                <td id="email">
                  {{ this.email }}
                  <!-- {{ decrypted }} -->
                </td>
              </tr>
              <tr>
                <th>
                  <div class="" style="font-size: 14px">
                    {{ $t("message.title-memberId") }}
                  </div>
                  <span class="require-memberId">
                    {{ $t("message.title-required") }}
                  </span>
                  </th>
                <td id="memberId">
                  <v-radio-group
                    mandatory
                  >
                    <v-radio
                      required
                      color="black"
                      style="overflow-wrap:anywhere; color: black"
                      :label="memberId"
                    ></v-radio>
                    <v-radio
                      :label="label_memberId"
                      disabled
                      color="black"
                    ></v-radio>
                  </v-radio-group>
                </td>
              </tr>
              <tr>
                <th class="">
                  <div class="">
                    <div style="font-size: 14px">
                      {{ $t("message.label-password") }}
                    </div>
                    <span class="require-password">
                      {{ $t("message.title-required") }}
                    </span>
                  </div>
                </th>
                <td id="password">
                  <v-text-field
                  v-model="password"
                  :type="showPassword ? 'text' : 'password'"
                  :error-messages="passwordErrors"
                  name="password"
                  autocomplete="on"

                  @input="$v.password.$touch()"
                  @blur="$v.password.$touch()"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                  outlined
                  background-color="#F7DBF0"

                />
                  <p class="paragraf-pass">
                    {{ $t("message.desc-password-field") }}
                    <!-- <strong>{{ $t("message.label-please-enter") }}</strong> -->
                    <!-- {{ $t("message.label-about-width-pass") }} -->
                    <!-- <strong>{{ $t("message.label-desc-pass") }}</strong> -->
                  </p>
                </td>
              </tr>
              <tr>
                <th class="">
                  <div class="" style="font-size: 14px">
                    {{$t("message.label-conf-password")}}
                  </div>
                  <span class="require-conf-password">
                    {{ $t("message.title-required") }}
                  </span>
                </th>
                <td id="conf-password">
                  <v-text-field
                  v-model="confirmPassword"
                  :type="showConPassword ? 'text' : 'password'"
                  :error-messages="confirmPasswordErrors"
                  name="password"
                  autocomplete="on"

                  @input="$v.confirmPassword.$touch()"
                  @blur="$v.confirmPassword.$touch()"
                  :append-icon="showConPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showConPassword = !showConPassword"
                  outlined
                  background-color="#F7DBF0"
                />
                </td>
              </tr>
            </tbody>
          </table>

          <div class="mt-4 mb-2">
            <h3>{{ $t("message.label-member-info") }}</h3>
            <v-divider style="background-color: grey"></v-divider>
          </div>
          <table>
            <tr>
              <th class="">
                <div class="d-flex flex-row justify-between align-center">
                  <div style="font-size: 14px">
                    {{ $t("message.title-name") }}
                  </div>
                  <span class="require-name">
                    {{ $t("message.title-required") }}
                  </span>
                </div>
                </th>
              <td id="nameKeluarga">
                <div class="d-flex flex-row justify-start align-start">
                  <span class="mt-2 mr-2" style="font-size: 14px">{{ $t("message.label-name-keluarga") }}</span>
                  <v-text-field
                  v-model="nameKeluarga"
                  :error-messages="nameKeluargaErrors"

                  @input="$v.nameKeluarga.$touch()"
                  @blur="$v.nameKeluarga.$touch()"
                  outlined
                  background-color="#F7DBF0"
                  ></v-text-field>
                  <span class="mt-2 mr-2 ml-2" style="font-size: 14px">{{ $t("message.label-name-asli") }}</span>
                  <v-text-field
                  v-model="name"

                  :error-messages="nameErrors"
                  @input="$v.name.$touch()"
                  @blur="$v.name.$touch()"
                  outlined
                  background-color="#F7DBF0"
                ></v-text-field>
                </div>
              <p class="desc-name">
                {{ $t("message.label-desc-name-field") }}
              </p>
              </td>
            </tr>
            <tr>
              <th class="">
                <span style="font-size: 14px">{{ $t("message.title-furigana") }}</span>

                <!-- <span class="">
                  required
                </span> -->
                </th>
              <td id="nameKeluargaKana">
                <div class="d-flex flex-row justify-start align-start">
                  <span class="mt-4 mr-2" style="font-size: 14px">{{ $t("message.label-name-keluarga-kana") }}</span>
                  <v-text-field
                  v-model="nameKeluargaKana"
                  :error-messages="nameKeluargaKanaErrors"
                  @input="$v.nameKeluargaKana.$touch()"
                  @blur="$v.nameKeluargaKana.$touch()"

                  outlined
                  background-color="#F7DBF0"
                  ></v-text-field>
                  <span class="mt-4 mr-2 ml-2" style="font-size: 14px">{{ $t("message.label-name-asli-kana") }}</span>
                  <v-text-field
                  v-model="nameKana"
                  :error-messages="nameKanaErrors"
                  @input="$v.nameKana.$touch()"
                  @blur="$v.nameKana.$touch()"

                  outlined
                  background-color="#F7DBF0"
                ></v-text-field>
                </div>
              <p style="font-size: 10px">
                {{ $t("message.label-full-width") }}
              </p>
              </td>
            </tr>
            <tr>
              <th class="">
                <div style="font-size: 14px">
                  {{ $t("message.label-sex") }}
                  <span class="require-gender">
                    {{ $t("message.title-required") }}
                  </span>
                </div>
              </th>
              <td id="gender">
                <v-radio-group v-model="sex" row mandatory>
                  <v-radio value="1" :label="male" style="font-size: 14px" color="black" />
                  <v-radio value="2" :label="female" style="font-size: 14px" color="black" />
              </v-radio-group>
              </td>
            </tr>
            <tr>
              <th class="">
                <div style="font-size: 14px">
                  {{ $t("message.label-bod") }}
                </div>
                <span class="require-birthday">
                  {{ $t("message.title-required") }}
                </span>
              </th>
              <td id="birthday">
                <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="birthday"
                persistent
                width="290px"
                >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="birthday"
                    append-icon="mdi-calendar"
                    :error-messages="birthdayErrors"
                    @input="$v.birthday.$touch()"
                    @blur="$v.birthday.$touch()"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    background-color="#F7DBF0"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="birthday" :max="maxDate()" scrollable color="#ff0090">
                  <v-spacer></v-spacer>
                  <v-btn text color="#ff0090" @click="modal = false">
                    {{ $t("message.btn-cancel") }}
                  </v-btn>
                  <v-btn
                    text
                    color="#ff0090"
                    @click="$refs.dialog.save(birthday)"
                  >
                    {{ $t("message.btn-ok") }}
                  </v-btn>
                </v-date-picker>
              </v-dialog>
              </td>
            </tr>
            <tr>
              <th class="">
                <div style="font-size: 14px">
                  {{ $t("message.label-postal") }}
                </div>
                <span class="require-postalcode">
                  {{ $t("message.title-required") }}
                </span>
                </th>
              <td id="postalcode">
                <div class="d-flex flex-row justify-center align-center">
                  <v-text-field
                    v-model="postalcode"
                    :error-messages="postalErrors"
                    @input="$v.postalcode.$touch()"
                    @blur="$v.postalcode.$touch()"

                    outlined
                    background-color="#F7DBF0"
                  />
                  <v-btn
                  class="ml-2 title-button"
                  @click.prevent="getPostalcode()"
                  outlined
                  depressed
                  :disabled="isPostal"
                  style="background-color: rgba(235,233,234,1)"
                  >
                    <span style="font-size: 10px">{{ $t("message.search")}}</span>
                  </v-btn>
                </div>
                <p style="font-size: 10px">
                  {{ $t("message.label-example-postcode") }}
                </p>
              </td>
            </tr>
            <tr>
              <th class="">
                <div style="font-size: 14px">
                  {{ $t("message.label-address") }} ({{
                      $t("message.label-prefecture")
                    }})
                </div>
                <span class="require-prefecture">
                  {{ $t("message.title-required") }}
                </span>
                </th>
              <td id="prefecture">
                <v-select
                v-model="prefecture"
                :items="listPrefecture"
                item-text="pref"
                :error-messages="prefectureErrors"
                @input="$v.prefecture.$touch()"
                @blur="$v.prefecture.$touch()"
                outlined

                background-color="#F7DBF0"
                ></v-select>
              </td>
            </tr>
            <tr>
              <th class="">
                <div style="font-size: 14px">
                  {{ $t("message.label-address") }} ({{
                      $t("message.label-city")
                    }})
                </div>
                <span class="require-city">
                  {{ $t("message.title-required") }}
                </span>
                </th>
              <td id="city">
                <v-select
                v-model="city"
                item-text="city"
                :items="listCity"
                :error-messages="cityErrors"
                @input="$v.city.$touch()"
                @blur="$v.city.$touch()"
                outlined

                background-color="#F7DBF0"
                ></v-select>
              </td>
            </tr>
            <tr>
              <th class="">
                <div style="font-size: 14px">
                  {{ $t("message.label-address") }} ({{
                      $t("message.label-district")
                    }})
                </div>
                </th>
              <td id="district">
                <v-select
                v-model="district"
                :items="listDistrict"
                item-text="district"
                outlined

                background-color="#F7DBF0"
                ></v-select>
              </td>
            </tr>
            <tr>
              <th class="">
                <div style="font-size: 14px">
                  {{ $t("message.label-address-ward") }}
                </div>
                </th>
              <td id="ward">
                <v-text-field
                v-model="ward"
                outlined

                background-color="#F7DBF0"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <th class="">
                <div style="font-size: 14px">
                  {{ $t("message.label-address-street_address") }}
                </div>
                </th>
              <td id="street_address">
                <v-text-field
                v-model="street_address"
                outlined

                background-color="#F7DBF0"
                ></v-text-field>
                <!-- <p class="desc-street_address">
                  {{ $t("message.label-desc-street_address") }}
                </p> -->
              </td>
            </tr>
            <tr>
              <th class="">
                <div style="font-size: 14px">
                  {{ $t("message.label-building") }}
                </div>
                </th>
              <td id="building">
                <v-text-field
                v-model="building"
                outlined
                background-color="#F7DBF0"
                ></v-text-field>
                <p class="desc-building">
                  {{ $t("message.label-desc-building") }}
                </p>
              </td>
            </tr>
            <tr>
              <th>
                <div style="font-size: 14px">
                  {{ $t("message.label-phone") }}
                </div>
                <span class="require-phone">
                  {{ $t("message.title-required") }}
                </span>
              </th>
              <td id="phone">
                <v-container class="grey lighten-5" style="padding:0">
                  <v-row no-gutters justify="space-between">
                    <v-col cols="3"
                    v-for="(key, i) in phoneFields"
                    :key="i"
                    >
                      <input style="border:1px solid grey;width:100%;font-size:14px;padding:10px 0; background-color: #F7DBF0"
                      class="text-center input-phone"
                      :data-length="key.length"
                      :data-index="i"
                      :ref="`input-${i}`"
                      v-model="key.value"
                      @input="handlePhone($event)"

                      />
                    </v-col>
                      <!-- @keyup="handleOnChangePhoneFields()" -->
                  </v-row>
                  <p v-if="!cekPhone && clicked" style="color: red; font-size: 10px; padding-top: 5px">{{ $t("message.err-msg-phone-number-required") }}</p>
                  <p v-if="isNull" style="color: red; font-size: 10px; padding-top: 5px">
                    英数字半角のみ
                  </p>
                </v-container>
                <p class="desc-phone">
                  {{ $t("message.label-desc-phone") }}
                </p>
              </td>
            </tr>
          </table>
          <div class="d-flex flex-row justify-center align-center me-0 pt-6 pb-6" tile>
            <div class="m-2">
              <v-btn color="#E93B81" type="submit"  @submit.prevent="checkRegister" height="45" width="250">
                <span style="color: white">{{ $t("message.btn-confirm-member") }}</span>
              </v-btn>
            </div>
          </div>
        </form>
      </div>
    </div>
  </v-container>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import { validationMixin } from "vuelidate";
import {
  required,
  maxValue,
  minLength,
  maxLength,
  email,
  sameAs,
  numeric,
} from "vuelidate/lib/validators";
import { mdiNotePlusOutline } from '@mdi/js';
// import Swal from "sweetalert2/dist/sweetalert2.js";
export default {
  name: "Register",
  mixins: [validationMixin],
  validations: {
    email: { required, email },
    name: { required, maxLength: maxLength(40) },
    password: { required, minLength: minLength(6), maxLength: maxLength(40) },
    confirmPassword: { required, sameAsPassword: sameAs("password") },
    phoneFields: { required, numeric: numeric, maxLength: maxLength(12) },
    birthday: {
      required,
      maxValue: (value) => value < new Date().toISOString(),
    },
    checkbox: { required },
    postalcode: { required, maxLength: maxLength(8) },
    prefecture: { required },
    city: { required },
    // district: { required },
    // ward: { required },
    // street_address: { required },
    nameKeluarga: { required, maxLength: maxLength(40) },
    nameKeluargaKana: { required, maxLength: maxLength(40) },
    nameKana: { required, maxLength: maxLength(40) },
  },
  data: () => ({
    email: localStorage.getItem('email'),
    memberId: localStorage.getItem("email"),
    checkbox: false,
    isNull: false,
    name: "",
    password: "",
    confirmPassword: "",
    cekPhone: false,
    clicked: false,
    showTerm: false,
    sex: "",
    postalcode: "",
    prefecture: "",
    list_pref: [],
    city: "",
    list_city: [],
    district: "",
    nameKeluarga: "",
    nameKana: "",
    nameKeluargaKana: "",
    list_district: [],
    ward: "",
    street_address: "",
    building: "",
    birthday: "1990-01-01",
    message: {},
    isValid: false,
    isPostal: true,
    maxValName: 12,
    date: new Date().toISOString().substr(0, 7),
    menu: false,
    modal: false,
    menu2: false,
    showPassword: false,
    showConPassword: false,
    status: null,
    phoneFields: [
      { length: 4, value: null },
      { length: 4, value: null },
      { length: 4, value: null },
    ],

  }),
  created() {
    this.changeLocale();
    this.previewRegister()
  },
  methods: {
    previewRegister() {
      if(localStorage.user_register === undefined) {
        return
      }
      let result = JSON.parse(localStorage.user_register)


      if(result.gender == 1) {
        this.sex = "男性"
      } else {
        this.sex = "女性"
      }

      // split value furigana
      let splitFurigana = result.furigana.split(" ")
      this.nameKeluargaKana = splitFurigana[0]
      this.nameKana = splitFurigana[1]

      // split value customer name
      let splitName = result.customer_name.split(" ")
      this.nameKeluarga = splitName[0]
      this.name = splitName[1]

      this.list_pref.push(result.prefecture)
      this.list_district.push(result.district)
      this.list_city.push(result.city)

      this.email = result.email
      this.password = result.password
      this.confirmPassword = result.password_confirmation
      // this.phone = result.phone
      this.phoneFields[0].value = result.phone0
      this.phoneFields[1].value = result.phone1
      this.phoneFields[2].value = result.phone2
      this.building = result.building
      this.memberId = result.memberId
      this.postalcode = result.postalcode
      this.city = result.city
      this.ward = result.ward
      this.district = result.district
      this.prefecture = result.prefecture
      this.street_address = result.street_address
      this.birthday = result.bod
      this.token = result.token
      this.message = result.message
    },
    changeLocale() {
      this.$vuetify.lang.current = 'ja'
    },
    checkRegister(){

      // let result = this.resultPreviewRegister;
      // let value = result.phone0+result.phone1+result.phone2;
      // console.log(value);
      let value
      this.cekPhone = false;

      if(this.phoneFields[0].value && this.phoneFields[1].value && this.phoneFields[2].value != ''){
        this.cekPhone = true;
      }
      // console.log(this.cekPhone);
      if(this.cekPhone == false){
        this.clicked = true
        return
      }
      // if(value == ''){
      //   this.clicked = true
      //   return
      // }
      for (let field of this.phoneFields) {
        value += field.value;
      }
      let checkSpace = value.indexOf(" ");
      if(checkSpace == -1) {
        this.isNull = false
      } else {
        this.isNull = true
      }
      this.handleRegister()

      // return value
      // this.cekPhone = value

      //  if(value.length >= 8 && value.length <= 12){
      //    this.isNull = false;
      //  }else{
      //    this.isNull = true;
      //  }
      //  return value;
    },
    handlePhone(e) {
      // Grab input's value
      let value = e.target.value;
      this.cekPhone = value

      // Grab data-index value
      let index = parseInt(e.target.dataset.index);
      // Grab data-length value
      let maxlength = e.target.dataset.length;

      if (this.phoneFields[index].value.length > maxlength) {
        e.preventDefault();
        this.phoneFields[index].value = value.slice(0, 4);
        try {
          this.$refs[`input-${parseInt(index + 1)}`][0].focus();
        } catch (e) {}
        return;
      }

        // Shift focus to next input field if max length reached
        if (value.length >= maxlength) {
          if (typeof this.phoneFields[index + 1] == 'undefined') {
            e.preventDefault();
            return;
          }
          this.$refs[`input-${parseInt(index + 1)}`][0].focus();
          e.preventDefault();
        }
    },
    handleRegister() {
      this.$v.$touch();
      this.message.title = this.$t("message.title-register-success-message");
      // this.isValid = true

      this.clicked = true
      let token = localStorage.getItem('token')

      // let token = localStorage.getItem('token')
      let name_user = this.nameKeluarga+ " " +this.name
      let furigana = this.nameKeluargaKana+" "+this.nameKana

      let refacPostcode = this.postalcode
      refacPostcode = refacPostcode.replace("-","")

      let payload = {
        email: this.email,
        customer_name: name_user,
        furigana: furigana,
        password: this.password,
        password_confirmation: this.confirmPassword,
        // phone: this.phone,
        phone0: this.phoneFields[0].value,
        phone1: this.phoneFields[1].value,
        phone2: this.phoneFields[2].value,
        gender: parseInt(this.sex),
        postalcode: refacPostcode,
        bod: this.birthday,
        prefecture: this.prefecture,
        district: this.district,
        ward: this.ward,
        city: this.city,
        street_address: this.street_address,
        building: this.building,
        memberId: this.memberId,
        token: token,
        message: this.message,
      }
      // console.log(payload);
        if(this.cekPhone && this.confirmPassword && this.name &&
          this.nameKana && this.nameKeluarga && this.nameKeluargaKana &&
          this.password && this.sex && this.prefecture && this.city &&
          this.postalcode && this.birthday &&
          this.clicked && !this.isNull) {
          localStorage.setItem("user_register", JSON.stringify(payload))
          this.$router.push('/checkRegistration')
        }
        this.isValid = false
        this.clicked = false
    },
    async getPostalcode() {
      let postalcode = this.postalcode
      postalcode = postalcode.replace("-","")
      let resp = await this.$store.dispatch("address_module/getAddressByPostal", {
        postal: postalcode
      })
      if(resp.data.length > 0) {
        resp.data.forEach(val => {
          this.city = val.city
          this.prefecture = val.pref
          this.district = val.town
          this.list_pref.push(val.pref)
          this.list_city.push(val.city)
          this.list_district.push(val.town)
        })
      } else {
        let message
        if(typeof resp.data.message === 'undefined') message = this.$t("message.err-msg-postal-code-notfound")
        else message = this.$t("message.err-msg-postal-code-api-error")

        this.list_pref.push("")
        this.list_city.push("")
        this.list_district.push("")

        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: false,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            },
          })
          Toast.fire({
            icon: 'warning',
            title: message
          })
      }
    },
    maxDate() {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        ("0" + today.getDate()).slice(-2);
      const timestamp = date;
      return timestamp;
    },
  },
  computed: {
    // resultPreviewRegister() {
    //   return JSON.parse(localStorage.user_register)
    // },
    label_memberId() {
      return this.$t("message.label-memberId")
    },
    phone() {
      let value = '';
      for (let field of this.phoneFields) {
        console.log(field);
        value += field.value;
       }
       return value;
     },
    listPrefecture() {
      return this.list_pref
    },
    listCity() {
      return this.list_city
    },
    listDistrict() {
      return this.list_district
    },
    male() {
      return this.$t("message.label-male");
    },
    female() {
      return this.$t("message.label-female")
    },
    emailErrors() {
      let errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push(this.$t("message.err-msg-email"));
      !this.$v.email.required && errors.push(this.$t("message.err-msg-email-required"));
      this.err = errors
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required &&
        errors.push(this.$t("message.err-msg-name-register"));
      !this.$v.name.maxLength &&
        errors.push(this.$t("message.err-msg-name-maxLength"));
      return errors;
    },
    nameKeluargaErrors() {
      const errors = [];
      if (!this.$v.nameKeluarga.$dirty) return errors;
      !this.$v.nameKeluarga.required &&
        errors.push(this.$t("message.err-msg-name-register"));
      !this.$v.nameKeluarga.maxLength &&
        errors.push(this.$t("message.err-msg-name-maxLength"));
      return errors;
    },
    nameKeluargaKanaErrors() {
      const errors = [];
      if (!this.$v.nameKeluargaKana.$dirty) return errors;
      !this.$v.nameKeluargaKana.required &&
        errors.push(this.$t("message.err-msg-name-register"));
      !this.$v.nameKeluargaKana.maxLength &&
        errors.push(this.$t("message.err-msg-name-maxLength"));
      return errors;
    },
    nameKanaErrors() {
      const errors = [];
      if (!this.$v.nameKana.$dirty) return errors;
      !this.$v.nameKana.required &&
        errors.push(this.$t("message.err-msg-name-register"));
      !this.$v.nameKana.maxLength &&
        errors.push(this.$t("message.err-msg-name-maxLength"));
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.minLength &&
        errors.push(this.$t("message.err-msg-password-minLength"));
      !this.$v.password.required &&
        errors.push(this.$t("message.err-msg-password-required"));
      !this.$v.password.maxLength &&
        errors.push(this.$t("message.err-msg-password-maxLength"));
      return errors;
    },
    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.confirmPassword.$dirty) return errors;
      !this.$v.confirmPassword.sameAsPassword &&
        errors.push(this.$t("message.err-msg-confirm-password-same"));
      !this.$v.password.required &&
        errors.push(this.$t("message.err-msg-confirm-password-required"));

      return errors;
    },
    postalErrors() {
      let errors = [];
      this.isPostal = false
      if (!this.$v.postalcode.$dirty) return errors;
      !this.$v.postalcode.required &&
        errors.push(this.$t("message.err-msg-postalcode-required"));
      // if (this.postalcode.slice(0, 3) != 507) {
      //   errors.push(this.$t("message.err-msg-postal-507"));
      // }
      !this.$v.postalcode.maxLength &&
        errors.push(this.$t("message.err-msg-maxLength-postcode"));
      if(errors.length > 0) {
        this.isPostal = true
      }
      return errors;
    },
    prefectureErrors() {
      let errors = [];
      if (!this.$v.prefecture.$dirty) return errors;
      !this.$v.prefecture.required &&
        errors.push(this.$t("message.err-msg-prefecture-required"));
      return errors;
    },
    cityErrors() {
      let errors = [];
      if (!this.$v.city.$dirty) return errors;
      !this.$v.city.required &&
        errors.push(this.$t("message.err-msg-city-required"));
      return errors;
    },
    // districtErrors() {
    //   let errors = [];
    //   if (!this.$v.district.$dirty) return errors;
    //   !this.$v.district.required &&
    //     errors.push(this.$t("message.err-msg-district-required"));
    //   return errors;
    // },
    // wardErrors() {
    //   let errors = [];
    //   if (!this.$v.ward.$dirty) return errors;
    //   !this.$v.ward.required &&
    //     errors.push(this.$t("message.err-msg-ward"));
    //   return errors;
    // },
    // street_addressErrors() {
    //   let errors = [];
    //   if (!this.$v.street_address.$dirty) return errors;
    //   !this.$v.street_address.required &&
    //     errors.push(this.$t("message.err-msg-street_address"));
    //   return errors;
    // },
    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.phoneFields.$dirty) return errors;
      !this.$v.phoneFields.required &&
        errors.push(this.$t("message.err-msg-phone-number-required"));
      !this.$v.phoneFields.numeric &&
        errors.push(this.$t("message.err-msg-phone-number-numeric"));
      !this.$v.phoneFields.maxLength &&
        errors.push(this.$t("message.err-msg-phone-number-maxLength"));

      return errors;
    },
    sexErrors() {
      const errors = [];
      if (!this.$v.sex.$dirty) return errors;
      !this.$v.sex.required &&
        errors.push(this.$t("message.err-msg-sex-required"));

      return errors;
    },
    birthdayErrors() {
      const errors = [];
      let today = new Date();
      let myDate = new Date(this.birthday);
      if (!this.$v.birthday.$dirty) return errors;

      !this.$v.birthday.required &&
        errors.push(this.$t("message.err-msg-bod-required"));

      return errors;
    },
  },
};
</script>

<style scoped>
#fixedbutton {
  position: fixed;
  bottom: 3em;
  right: 1em;
}
.Tos-layout {
  border: 1px solid black;
  width: 100%;
  height: 14vh;
  overflow-x: scroll;
}
.Tos-all-term {
  border: 1px solid black;
  width: 100%;
  height: auto;
}
table {
  min-width: 95%;
  margin: auto;
  /* border: 1px solid #616161; */
  border-bottom: 2px solid #616161;
}

td {
  min-width: 40%;
  /* width: 20%; */
  flex: 1;

}
tr {
  display: flex;
  flex-wrap: wrap;
  border-top: 2px solid #616161;
}
tr + tr {
  border-top: 2px solid #616161;
  border-color: #616161;
  padding: 0px !important;
}
tr th {
  background-color: #e0e0e0;
  width: 33%;
  text-align: left;
  padding: 5px;
  word-wrap: break-word;
  border-right: 2px solid #616161;
  /* text-overflow: w; */
}
tr td {
  padding: 5px !important;
  word-wrap: break-word;
}

#memberId {
  width: 231.5px !important;
	/* height: 17px; */
}

.pembatas {
  /* left: 228.954px; */
  /* position: absolute; */
  top: -10px;
  /* overflow: visible; */
  /* width: 18.091px; */
  /* height: 17px; */
  text-align: center;
  font-family: Hiragino Maru Gothic Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(35,34,34,1);
}

.desc-building {
  /* width: 242.409px; */
  display: table-row;
  text-align: left;
  font-size: 10px;
}

.desc-phone {
  width: 242.409px;
  height: 14px;
  text-align: left;
  font-size: 10px;
  padding-top: 5px;
}

.desc-name {
  /* width: 242.409px; */
  display: table-row;
  height: 44px;
  text-align: left;
  font-size: 10px;
}

.title-button {
  left: 0px;
	top: -10px;
  width: 102.091px;
  height: 17px;
  text-align: center;
  font-size: 14px;
}

.paragraf-pass {
  /* left: 162.591px;
	top: 300.25px; */
  display: table-row;
  font-size: 10px;
  width: 242.409px;
	height: 71px;
}

.desc-street_address {
  /* width: 242.409px; */
  display: table-row;
  height: 56px;
  text-align: left;
  font-size: 10px;
}

.require-memberId {
  /* left: 70px;
  top: 370.411px; */
  left: 20%;
  position: absolute;
  overflow: visible;
  width: 50px;
  height: 12px;
  text-align: left;
  font-family: Hiragino Maru Gothic Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: rgba(255,0,144,1);
}
.require-password {
  position: absolute;
  /* overflow: visible; */
  left: 20%;
  width: 50px;
  height: 12px;
  text-align: left;
  font-family: Hiragino Maru Gothic Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: rgba(255,0,144,1);
}
.require-conf-password {
  left: 20%;
  position: absolute;
  overflow: visible;
  width: 50px;
  height: 12px;
  text-align: left;
  font-family: Hiragino Maru Gothic Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: rgba(255,0,144,1);
}
.require-name {
  position: absolute;
  left: 20%;
  width: 50px;
  height: 12px;
  text-align: left;
  font-family: Hiragino Maru Gothic Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: rgba(255,0,144,1);
}
.require-furigana {
  position: absolute;
  left: 20%;
  width: 50px;
  height: 12px;
  text-align: left;
  font-family: Hiragino Maru Gothic Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: rgba(255,0,144,1);
}
.require-gender {
  position: absolute;
  left: 20%;
  width: 50px;
  height: 12px;
  text-align: left;
  font-family: Hiragino Maru Gothic Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: rgba(255,0,144,1);
}
.require-birthday {
  left: 20%;
  position: absolute;
  width: 50px;
  height: 12px;
  text-align: left;
  font-family: Hiragino Maru Gothic Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: rgba(255,0,144,1);
}
.require-postalcode {
  left: 20%;
  position: absolute;
  width: 50px;
  height: 12px;
  text-align: left;
  font-family: Hiragino Maru Gothic Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: rgba(255,0,144,1);
}
.require-prefecture {
  left: 20%;
  position: absolute;
  width: 50px;
  height: 12px;
  text-align: left;
  font-family: Hiragino Maru Gothic Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: rgba(255,0,144,1);
}
.require-city {
  left: 20%;
  position: absolute;
  width: 50px;
  height: 12px;
  text-align: left;
  font-family: Hiragino Maru Gothic Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: rgba(255,0,144,1);
}
.require-district {
  left: 20%;
  position: absolute;
  width: 50px;
  height: 12px;
  text-align: left;
  font-family: Hiragino Maru Gothic Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: rgba(255,0,144,1);
}
.require-ward {
  left: 20%;
  position: absolute;
  width: 50px;
  height: 12px;
  text-align: left;
  font-family: Hiragino Maru Gothic Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: rgba(255,0,144,1);
}
.require-street_address {
  left: 20%;
  position: absolute;
  width: 50px;
  height: 12px;
  text-align: left;
  font-family: Hiragino Maru Gothic Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: rgba(255,0,144,1);
}
.require-phone {
  left: 20%;
  position: absolute;
  width: 50px;
  height: 12px;
  text-align: left;
  font-family: Hiragino Maru Gothic Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: rgba(255,0,144,1);
}

.register {
  background-color: #fff;
  /* height: 100vh; */
  max-width: 100vw !important;
  /* width: 100% !important; */
  height: 100%;
}
@media only screen and (max-width: 320px) {
  .register {
    background-color: #fff;
    /* height: 135vh; */
    /* background-color: darkblue;
    height: 100vh; */
    max-width: 100vw !important;
  }
}
.email-disable #input-7 {
  color: white !important;
}
.email-disable .v-input__slot::before {
  display: none;
}
/* .email-disable .v-input__slot::after {
  display: none;
} */
.email-disable .v-input__control .v-input__slot .v-text-field__slot input {
  color: white !important;
  padding-top: 0px !important;
  margin-top: 0px !important;
}
.input-email {
  padding-top: 0px;
}
.input-phone:focus-visible {
  outline: rgb(0, 96, 192) auto 1px !important;
}
/* .v-text-field__details {
  display: none;
} */
</style>
